<template>
  <div class="vg_wrapper">
    <el-card>
      <div class="vd_search_group">
        <el-form :inline="true" size="min" label-width="120px" :model="searchForm" ref="searchForm" @keyup.enter.native="getMdtksNow()">
          <el-row>
            <el-col :md="8">
              <el-form-item label="材料编号：">
                <el-input size="small" v-model.trim="searchForm.mdrl_no" clearable placeholder="请填写材料编号"></el-input>
              </el-form-item>
            </el-col>
            <el-col :md="8">
              <el-form-item label="材料名称：">
                <el-input size="small" v-model.trim="searchForm.mdrl_name" clearable placeholder="请填写材料名称"></el-input>
              </el-form-item>
            </el-col>
            <el-col :md="8">
              <el-form-item label="材料类型：">
                <el-select size="small" v-model="searchForm.mdrl_type" placeholder="请选择材料类型" clearable>
                  <el-option v-for="item in mdrlType" :key="item.id" :label="item.label" :value="item.id"> </el-option>
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :md="8">
              <el-form-item label="库存状态：">
                <el-select size="small" v-model="searchForm.mdtk_status" placeholder="请选择库存状态" clearable>
                  <el-option v-for="item in state" :key="item.id" :label="item.label" :value="item.id"> </el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :md="16">
              <el-form-item label="修改时间范围：">
                <div class="block vg_mr_32">
                  <el-date-picker
                    size="small"
                    v-model="searchForm.timeValue"
                    type="datetimerange"
                    range-separator="至"
                    start-placeholder="开始日期"
                    end-placeholder="结束日期"
                  >
                  </el-date-picker>
                </div>
              </el-form-item>
              <el-form-item>
                <el-button size="small" type="primary" icon="el-icon-search" @click="getMdtksNow()" class="vg_ml_16">查询 </el-button>
                <el-button type="info" size="small" class="vd_export" @click="buttonRefresh()"
                  ><i class="el-icon-refresh-right"></i> 刷新</el-button
                >
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col>
              <el-form-item>
                <el-button type="danger" size="small" class="vd_export" @click="remove()" icon="el-icon-delete">删除</el-button>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
      </div>
      <div class="vd_button_group vg_mtb_16"></div>
      <el-row>
        <el-col :md="24">
          <el-table
            ref="multiTable"
            @sort-change="sortChange"
            :data="tableData"
            border
            v-loading="loadFlag"
            @selection-change="handleSelectionChange"
          >
            <el-table-column type="selection" align="center"></el-table-column>
            <el-table-column label="材料编号" prop="mdrl_no" />
            <el-table-column label="材料图片" align="center">
              <template v-slot="scope">
                <el-popover v-if="scope.row.imge_url" placement="right" width="400" trigger="hover">
                  <div>
                    <el-image style="width: 100%" class="vd_popimg" :src="formatPic(scope, 'l')" fit="fill"> </el-image>
                  </div>
                  <el-image slot="reference" class="vd_elimg" :src="formatPic(scope, 's')" fit="fill"> </el-image>
                </el-popover>
                <span v-else class="vg_9f9a9a">暂无</span>
              </template>
            </el-table-column>
            <el-table-column label="材料名称" prop="mdrl_name" show-overflow-tooltip />
            <el-table-column label="材料规格" prop="mdrl_spec" show-overflow-tooltip />
            <el-table-column label="材料材质" prop="mdrl_qual" show-overflow-tooltip />
            <el-table-column label="材料单位" prop="mdrl_unit" show-overflow-tooltip />
            <el-table-column
              label="材料重量"
              prop="mdrl_weight"
              show-overflow-tooltip
              :formatter="({ mdrl_weight }) => mdrl_weight + '公斤'"
            />
            <el-table-column label="材料类型" prop="mdrl_type" :formatter="formatLeavType" />
            <el-table-column label="库存状态" :show-overflow-tooltip="true" align="center">
              <template v-slot="scope">
                <el-tag :type="getStatusName(scope.row.mdtk_status).type" size="mini">{{
                  getStatusName(scope.row.mdtk_status).name
                }}</el-tag>
              </template>
            </el-table-column>
            <el-table-column label="库存数量" prop="mdtk_num">
              <template v-slot="scope">
                <span v-if="scope.row.mdtk_num">
                  {{ scope.row.mdtk_num | formatMdtkNum }}
                </span>
                <span v-else class="vg_9f9a9a">0.0000</span>
              </template>
            </el-table-column>
            <el-table-column label="安全库存" prop="mdrl_stock_limit">
              <template v-slot="scope">
                {{ scope.row.mdrl_stock_limit | formatMdrlStockLimit }}
              </template>
            </el-table-column>
            <el-table-column label="修改时间" prop="update_time" :formatter="formatDate" :show-overflow-tooltip="true"></el-table-column>
            <el-table-column label="二维码信息" sortable width="120px" align="center">
              <template v-slot="scope">
                <div class="QrDiv">
                  <el-button size="mini" type="success" class="QrBtn" @click="getQrCodeData(scope.row)">查看二维码</el-button>
                </div>
              </template>
            </el-table-column>
          </el-table>
        </el-col>
      </el-row>
      <el-row>
        <el-col :md="24">
          <pubPagination :totalPage="totalPage" @changePageSearch="changePageSearch" ref="pubPagination"> </pubPagination>
        </el-col>
      </el-row>
      <el-dialog :title="title" :visible.sync="dialogVisible" width="50%" :before-close="handleClose">
        <el-row v-if="qrCodeList.length > 0">
          <el-col class="handleRow" :md="5" v-for="item in qrCodeList" :key="item.mqcd_id">
            <el-row>
              <el-col :md="8">
                <div class="lableDiv">
                  <span>二维码：</span>
                </div>
              </el-col>
              <el-col :md="7">
                <div>
                  <el-image class="vd_elimg" v-if="item.mqcd_image" :src="formatPicForQrCode(item, 'l')" fit="fill"> </el-image>
                  <span v-else class="vg_9f9a9a">暂无</span>
                </div>
              </el-col>
            </el-row>
            <el-row>
              <span>材料名称：</span>
              <span v-if="item.mdrl_name">{{ item.mdrl_name }}</span>
              <span v-else class="vg_9f9a9a">暂无</span>
            </el-row>
            <el-row>
              <span>材料编号：</span>
              <span v-if="item.mdrl_no">{{ item.mdrl_no }}</span>
              <span v-else class="vg_9f9a9a">暂无</span>
            </el-row>
            <el-row>
              <span>库位：</span>
              <span v-if="item.mqcd_bin">{{ item.mqcd_bin }}</span>
              <span v-else class="vg_9f9a9a">暂无</span>
            </el-row>
            <el-row>
              <span>数量：</span>
              <span v-if="item.mqcd_num">{{ item.mqcd_num }}</span>
              <span v-else class="vg_9f9a9a">暂无</span>
            </el-row>
            <el-row class="delRow">
              <el-button size="mini" type="danger" @click="delQrCode(item)">删除</el-button>
            </el-row>
          </el-col>
        </el-row>
        <el-row v-else class="noDataRow">暂无数据</el-row>
      </el-dialog>
    </el-card>
  </div>
</template>

<script>
import { get, post } from '@api/request';
import { mdtkAPI } from '@api/modules/mdtk';
import { mqcdAPI } from '@api/modules/mqcd';
import pubPagination from '@/components/common/pubPagination';
import selectSectionType from '@/views/component/selectSectionType';
import helper from '@assets/js/helper.js';

export default {
  name: 'MdtkList',
  components: {
    pubPagination,
    selectSectionType
  },
  data() {
    return {
      title: '查看二维码信息',
      tableData: [],
      dialogVisible: false,
      searchForm: {
        mdrl_no: null,
        mdrl_thick: null,
        mdrl_sort: null,
        mdrl_type: null,
        flag: 0,
        mdrl_width: null, //材料门幅
        mdrl_spec: null, //材料规格
        mdrl_field: null,
        colr_class: [],
        timeValue: [],
        mdtk_status: null,
        belo_dept_id: null //所属部门
      },
      qrCodeList: [],
      qr_mdrl_id: null,
      totalPage: 0,
      btn: {},
      loadFlag: true,
      currentPage: 1,
      mdrlType: [
        { id: 1, label: '主材料' },
        { id: 2, label: '辅材料' },
        { id: 3, label: '其他' }
      ],
      state: [
        { id: 0, label: '库存不足' },
        { id: 1, label: '库存充足' }
      ],
      multipleSelection: [],
      optnColorList: [],
      isDyj: true,
      beloDeptId: null
    };
  },
  created() {
    if (this.$route.query.mdrl_no) {
      this.searchForm.mdrl_no = this.$route.query.mdrl_no;
    }
    this.initData();
  },
  mounted() {
    this.$EventBus.$on('uploading', res => {
      this.getMdtksList();
    });
  },
  filters: {
    formatMdtkNum(row) {
      return helper.haveFour(row);
    },
    formatMdrlStockLimit(row) {
      return helper.haveFour(row);
    },
    formatQrcdMdrlThick(row) {
      return helper.reservedDigits(row);
    },
    formaUnitM(row) {
      return helper.haveFour(row) + '米';
    },
    formaUnitH(row) {
      return helper.reservedDigits(row) + '毫米';
    }
  },
  methods: {
    initData() {
      this.getMdtksList();
    },
    // 获取二维码信息
    getMdtksList() {
      const timeNewVal = this.helper.getTime(this.searchForm.timeValue);
      this.searchForm.start_time = timeNewVal.startTime;
      this.searchForm.end_time = timeNewVal.endTime;
      this.searchForm.page_no = this.currentPage;
      get(mdtkAPI.getMdtks, this.searchForm).then(res => {
        if (res.data.code === 0) {
          this.loading = false;
          this.tableData = res.data.data.list;
          this.totalPage = res.data.data.total;
          this.btn = res.data.data.btn;
          setTimeout(() => {
            this.loadFlag = false;
          }, 500);
        }
      });
    },
    // 转换二维码图片
    formatPicForQrCode(scope, type) {
      return this.helper.picUrl(scope.mqcd_image, type, scope.create_time);
    },
    // 删除
    remove() {
      if (this.multipleSelection.length === 0) {
        return this.$message.warning('至少选择一条数据');
      }
      this.$confirm('此操作将永久删除该材料, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        let ids = this.multipleSelection.map(({ mdtk_id }) => Object.values({ mdtk_id }).toString());
        post(mdtkAPI.destroyMdtkByIds, { mdtk_id_list: ids }).then(res => {
          if (res.data.code === 0) {
            this.$message.success(res.data.msg);
            if (ids.length === this.tableData.length) {
              if (this.currentPage > 1) {
                this.currentPage = this.currentPage - 1;
                this.$refs.pubPagination.currentPage = this.currentPage;
              }
            }
            this.getMdtksList();
            return;
          }
          this.$message.warning(res.data.msg);
        });
      });
    },
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    // 刷新
    buttonRefresh() {
      this.searchForm = {
        page_no: 1
      };
      if (this.isDyj) {
        this.searchForm.belo_dept_id = this.beloDeptId;
      } else {
        this.searchForm.belo_dept_id = null;
        this.$refs.selectSectionType.value2 = '全部';
      }
      this.loadFlag = true;
      this.currentPage = 1;
      this.getMdtksList();
      this.$refs.pubPagination.currentPage = 1;
    },
    // 图片类型
    formatPic(scope, type) {
      return this.helper.picUrl(scope.row.imge_url, type, scope.row.create_time);
    },
    // 转换合同状态
    getStatusName(status) {
      if (status === 1) {
        return { name: '库存充足', type: 'success' };
      } else if (status === 0) {
        return { name: '库存不足', type: 'warning' };
      }
    },
    // 查询方法
    getMdtksNow() {
      this.loadFlag = true;
      this.$refs.pubPagination.currentPage = 1;
      this.currentPage = 1;
      this.getMdtksList();
    },
    // 状态查询
    getStatusVal(val) {
      this.searchForm.status = val;
    },
    // 分页查询
    changePageSearch(val) {
      this.currentPage = val;
      this.getMdtksList();
    },
    // 材料类型
    formatLeavType(row) {
      if (row.mdrl_type === 1) {
        return '主材料';
      } else if (row.mdrl_type === 2) {
        return '辅材料';
      } else if (row.mdrl_type === 3) {
        return '其他';
      }
    },
    // 时间转换
    formatDate(row) {
      return this.helper.toStringDate(row.update_time);
    },
    //查询范围
    getStatusVal2(val) {
      this.searchForm.belo_dept_id = val;
    }, //升序 降序
    sortChange(val) {
      if (val.prop) {
        if (val.order === 'ascending') {
          this.searchForm.mdrl_sort = 'asc';
          this.searchForm.mdrl_field = val.prop;
        } else if (val.order === 'descending') {
          this.searchForm.mdrl_sort = 'desc';
          this.searchForm.mdrl_field = val.prop;
        } else {
          this.searchForm.mdrl_sort = '';
          this.searchForm.mdrl_field = '';
        }
      } else {
        if (val.order === 'ascending') {
          this.searchForm.flag = 0;
        } else if (val.order === 'descending') {
          this.searchForm.flag = 1;
        } else {
          this.searchForm.flag = 0;
        }
      }
      this.loadFlag = true;
      this.searchForm.page_no = 1;
      this.getMdtksList();
    },
    //二维码信息按钮
    getQrCodeData(row) {
      this.qr_mdrl_id = row.mdrl_id;
      this.getQrCodeDataMain(this.qr_mdrl_id);
    },
    // 获取二维码信息接口
    getQrCodeDataMain(id) {
      get(mdtkAPI.getMdtkMqcds, { mdrl_id: id }).then(res => {
        if (res.data.code === 0) {
          this.qrCodeList = res.data.data.list;
          this.dialogVisible = true;
          return;
        }
        this.$message.error(res.data.msg);
      });
    },
    // 关闭弹窗
    handleClose() {
      this.dialogVisible = false;
    },
    // 删除二维码确认
    delQrCode(item) {
      this.$confirm('确定删除该二维码？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        post(mqcdAPI.deleteMqcdByIds, { mqcd_id_list: [item.mqcd_id] }).then(res => {
          let mg = res.data.msg;
          if (res.data.code === 0) {
            let tp = 'success';
            this.$message({ message: mg, type: tp });
            this.getQrCodeDataMain(this.qr_mdrl_id);
          } else {
            let tp = 'error';
            this.$message({ message: mg, type: tp });
            this.getQrCodeDataMain(this.qr_mdrl_id);
          }
        });
      });
    }
  }
};
</script>

<style scoped lang="scss">
.vd_elimg {
  width: 60px;
  height: 60px;
  margin-top: 5px;
}

.vd_dis {
  display: flex;
}

.vd_div {
  width: 40px;
  height: 20px;
  border: none;
  margin-right: 5px;
}

.vd_popimg {
  width: 400px;
  height: 400px;
}

// 查看二维码信息按钮
.QrBtn {
  padding: 8px;
}

.QrDiv {
  display: flex;
  align-items: center;
  justify-content: center;
}

.lableDiv {
  margin-top: 50%;
}
.delRow {
  margin-top: 10px;
  text-align: center;
}
.handleRow {
  border: 1px solid rgba($color: grey, $alpha: 0.3);
  padding: 10px;
  margin-right: 20px;
}
.noDataRow {
  padding-left: 45%;
  color: grey;
}
</style>
